/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Row, Col, Paragraph, Button } from 'components/atoms';
import { protectedRouts } from 'constants/routes';
import {
  clearApplicationData,
  addNewApplicantData,
  setApplicationDataIsStored,
} from 'app/slices/application';
import {
  getApplicantsData,
  getApplicationStatusData,
  getApplicationType,
  getDocsAreValid,
  getFullApplicationData,
  getUser,
} from 'app/services/selector-helpers';
import { colors } from 'constants/colors';
import { removeUser } from 'app/slices/user';
import { convertRoutesToItems, submenuKeys } from 'utils/helpers';
import useGetApplicationControls from 'utils/hooks/useGetApplicationControls';
import { isNewMortgageCompleted } from 'utils/isNewMortgageCompleted';
import { isRefinanceCompleted } from 'utils/isRefinanceCompleted';
import { isRenewalCompleted } from 'utils/isRenewalCompleted';
import { notification } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { savedAppId } from '../../configs/local-storage';
import SidebarFooter from './sidebar-footer';
import provider from '../../services/main-client';
import { applicationsEndpoint } from '../../constants/endpoints';
import useApiCall from '../../utils/hooks/useApiCall';
import useGetInfosByType from '../../utils/hooks/useGetInfosByType';
import useStoreApplicationById from '../../utils/hooks/useStoreApplicationById';
import HomeSidebar from './home-sidebar';

const MobileMenuWrapper = styled.div`
  overflow: auto;
  background: #fff;
  backdrop-filter: blur(10px);
  width: 100vw;
  top: 75px;
  left: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  transition: all ease 0.3s;
  padding-bottom: 60px;
  ${props =>
    props.collapsed &&
    props.collapsed === 'collapsed' &&
    css`
      left: 0;
    `}
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const [updateApplicationApi] = provider(applicationsEndpoint, ['update'], false);

const MobileSidebar = ({
  role,
  setCollapsed,
  collapsed,
  openKeys,
  setOpenKeys,
  hideSteps,
  customSidebar,
  isSubmitted,
  ...props
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const appStatusData = useSelector(getApplicationStatusData);
  const [applicationControls] = useGetApplicationControls({ role });
  const [applicationStatus] = useGetInfosByType('applicationStatus');
  const [storeApplicationData] = useStoreApplicationById();
  const applicantsData = useSelector(getApplicantsData);
  const applicationType = useSelector(getApplicationType);
  const docsAreValid = useSelector(getDocsAreValid);
  const application = useSelector(getFullApplicationData);
  const [, updateAppLoading, updateAppCall] = useApiCall(updateApplicationApi);

  const handleLogout = () => {
    dispatch(removeUser());
    dispatch(clearApplicationData());
    dispatch(setApplicationDataIsStored(false));
    navigate('/sign-in');
  };
  const addCoApplicant = () => {
    dispatch(addNewApplicantData());
    navigate(`/identification/${applicantsData?.length}`);
    setCollapsed(false);
  };

  const items = role ? convertRoutesToItems(protectedRouts[role], applicationControls) : [];

  const checkSubmitActive = useMemo(() => {
    const typeData = {
      newMortgage: isNewMortgageCompleted(applicationControls),
      refinanceMortgage: isRefinanceCompleted(applicationControls),
      mortgageRenewal: isRenewalCompleted(applicationControls),
    };
    return typeData[applicationType] && docsAreValid && appStatusData?.code === 'created';
  }, [application, docsAreValid]);

  const submitApplication = () => {
    updateAppCall(
      {
        id: savedAppId(),
        values: {
          data: {
            applicationStatusId: applicationStatus?.find(el => el.code === 'taken')?.id,
          },
        },
      },
      () => {
        storeApplicationData(user?.id, savedAppId(), () => {
          notification.success({
            message: 'The application has been submitted successfully.',
            maxCount: 1,
          });
        });
      }
    );
  };

  return (
    <MobileMenuWrapper collapsed={collapsed ? 'collapsed' : ''}>
      <Row width='100%'>
        <Col span={24}>
          {!customSidebar && (
            <Menu
              openKeys={openKeys}
              onOpenChange={setOpenKeys}
              mode='inline'
              selectedKeys={pathname}
              type='sidebar'
              items={items}
              onClick={item => {
                if (!submenuKeys.includes(item.key)) {
                  navigate(item.key);
                  setCollapsed(false);
                }
              }}
            ></Menu>
          )}
          {customSidebar && <HomeSidebar setCollapsed={setCollapsed} />}
          {!customSidebar && (
            <>
              {!!savedAppId() && role === 'customer' && !isSubmitted && !hideSteps && (
                <div style={{ padding: '15px 50px' }}>
                  <Paragraph mb={0} fw={400} color={colors.main_color} onClick={addCoApplicant}>
                    Add Co-applicant <PlusCircleOutlined />
                  </Paragraph>
                </div>
              )}
              {user?.role?.type === 'customer' &&
                ([
                  'taken',
                  'approved',
                  'fundReady',
                  'mortgageFunded',
                  'rejected',
                  'docRejected',
                ].includes(appStatusData?.code) ? (
                  <div style={{ padding: '30px', color: '#717579' }}>
                    <Paragraph mb={5} fz={16} fw={600} color={colors.main_color}>
                      {appStatusData?.value}
                    </Paragraph>
                    <ReactMarkdown
                      children={appStatusData?.note || ''}
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                    />
                    {appStatusData?.code === 'docRejected' && (
                      <Button
                        style={{ width: '100%', marginTop: '5px' }}
                        type='primary'
                        onClick={submitApplication}
                        loading={updateAppLoading}
                      >
                        Resubmit
                      </Button>
                    )}
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      disabled={!checkSubmitActive}
                      style={{ width: '90%', margin: '15px' }}
                      type='primary'
                      onClick={submitApplication}
                      loading={updateAppLoading}
                    >
                      Submit
                    </Button>
                  </div>
                ))}
            </>
          )}
          {!customSidebar && (
            <>
              {user?.role?.type === 'customer' && (
                <SidebarFooter user={user?.role?.type} isApp={true} />
              )}
            </>
          )}
          {user && (
            <div
              style={{ margin: '20px 50px 120px 50px', color: colors.main_color }}
              onClick={handleLogout}
            >
              Log out
            </div>
          )}
        </Col>
      </Row>
    </MobileMenuWrapper>
  );
};

export default MobileSidebar;
